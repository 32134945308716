<template>
  <div>
    <VTitle :title="$t('app.details')" class="section-title" />

    <div class="grid responsive-cols gap-8">
      <div>
        <ProfileForm
          :data="userData"
          :is-loading="isLoading"
          class="mb-8"
          @click:save="onClickSaveProfile"
        />

        <DetailsForm :data="user" :readonly="true">
          <template #title>
            {{ $t("app.personal_information") }}
          </template>

          <template #actions>
            <div />
          </template>
        </DetailsForm>
      </div>

      <div>
        <UserPasswordForm
          :is-saving="isSaving"
          has-confirm-password
          :user="user"
          class="mb-8"
          @click:save="onClickSavePassword"
        />

        <UserAPIAccessForm
          :id="user.id"
          :api-key="user.api_token"
          class="mb-8"
          @click:regenerate="onClickRegenerate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, provide } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
// Composables
import useRequest from "@/composables/useRequest";
// Components
import ProfileForm from "./ProfileForm";
import DetailsForm from "./Form";
import VTitle from "@/components/VTitle";
import UserPasswordForm from "@/views/administration/users/UserPassword.vue";
import UserAPIAccessForm from "@/views/administration/users/UserAPIAccess.vue";

export default {
  components: {
    DetailsForm,
    UserPasswordForm,
    UserAPIAccessForm,
    ProfileForm,
    VTitle
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    // MISC
    const { t } = useI18n();
    const store = useStore();

    // Composables
    const { isRequesting: isLoading, request: saveProfile } = useRequest();
    const { isRequesting: isSaving, request: savePassword } = useRequest();
    const { isRequesting: isRegenerating, request: regenerate } = useRequest();

    // Provide
    provide("isRegenerating", isRegenerating);

    // COMPUTED
    const documentTitle = computed(() => {
      const title = `${t("app.details")} - ${t("app.profile")}`;

      const { firstname, lastname } = props.user ?? {};
      if (firstname && lastname) {
        return `${firstname} ${lastname} - ${title}`;
      }

      return title;
    });
    const userData = computed(() => ({
      ...props.user,
      email_notification: getSettingValue("NOTIFY_EMAIL"),
      sms_notification: getSettingValue("NOTIFY_SMS")
    }));

    // METHODS
    const getSettingValue = code => {
      const settings = props.user?.settings || [];
      const setting = settings.find(s => s.code === code);
      const values = setting ? JSON.parse(setting.values) : null;
      const defaultValue = values ? values.default : "No";

      return setting?.pivot?.value || defaultValue;
    };

    const onClickSaveProfile = async data => {
      const response = await saveProfile({
        endpoint: "my.profile.update",
        data
      });

      store.dispatch("user/updateUser", response?.payload?.data || {});
    };

    const onClickRegenerate = async () => {
      const response = await regenerate({
        endpoint: "my.key",
        showToaster: false
      });

      store.dispatch("user/updateUser", response?.payload?.data || {});
      store.dispatch("user/setApiToken", response?.payload?.data?.api_key);
    };

    const onClickSavePassword = async data => {
      await savePassword({
        endpoint: "my.password",
        data
      });
    };

    return {
      userData,
      documentTitle,
      onClickSaveProfile,
      onClickSavePassword,
      onClickRegenerate,
      // useRequest
      isLoading,
      isSaving
    };
  }
};
</script>
