<template>
  <VCard>
    <template #title>
      <h2 class="font-medium text-base mr-auto">
        {{ $t("app.api_access") }}
      </h2>
    </template>

    <template #content>
      <label class="form-label w-full">
        {{ $t("app.api_token") }}
      </label>

      <div class="flex">
        <input
          :value="apiKey"
          type="text"
          readonly
          class="form-control mr-2 api-key-value"
        />

        <VButton
          class="btn-outline-primary mr-2"
          icon="fa-copy"
          @click="onClickCopy"
        />

        <VButton
          class="btn-primary"
          :is-loading="isRegenerating"
          :label="$t('app.regenerate')"
          @click="$emit('click:regenerate')"
        />
      </div>
    </template>
  </VCard>
</template>

<script>
import { inject } from "vue";
import { useI18n } from "vue-i18n";
// Components
import VButton from "@/components/VButton";
import VCard from "@/components/VCard";

export default {
  components: {
    VButton,
    VCard
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    apiKey: {
      type: String,
      required: true
    }
  },
  emits: ["click:regenerate"],
  setup() {
    // MISC
    const { t } = useI18n();
    const toast = inject("toast");
    const isRegenerating = inject("isRegenerating");

    // METHODS
    const onClickCopy = () => {
      const el = document.querySelector(".api-key-value");
      el.focus();
      el.select();

      try {
        const hasCopied = document.execCommand("copy");

        if (hasCopied) {
          toast.show(t("app.copied"), "Success");
        } else {
          toast.show(t("app.unable_copy"), "Failure");
        }
      } catch (err) {
        toast.show(t("app.unable_copy"), "Failure");
      }
    };

    return {
      onClickCopy,
      isRegenerating
    };
  }
};
</script>
