<template>
  <FormCard
    :is-loading="isLoading"
    hide-actions
    @click:cancel="onCancel"
    @click:save="onSave"
  >
    <template v-if="$slots.title" #title>
      <slot name="title" />
    </template>

    <template #content>
      <div class="grid grid-cols-2 gap-x-8 gap-y-2">
        <VText
          v-model="v.username.$model"
          :label="$t('app.username')"
          :readonly="readonly"
          :errors="v.username.$errors"
        />

        <VText
          v-model="v.external_code.$model"
          :label="$t('app.external_code')"
          :readonly="readonly"
          :errors="v.external_code.$errors"
        />

        <VText
          v-model="v.firstname.$model"
          :label="$t('app.firstname')"
          :readonly="readonly"
          :errors="v.firstname.$errors"
        />

        <VText
          v-model="v.lastname.$model"
          :label="$t('app.lastname')"
          :readonly="readonly"
          :errors="v.lastname.$errors"
        />

        <VText
          v-model="v.title.$model"
          :label="$t('app.position')"
          :readonly="readonly"
          :errors="v.title.$errors"
        />

        <VText
          v-model="v.designations.$model"
          :label="$t('app.designations')"
          :readonly="readonly"
          :errors="v.designations.$errors"
        />

        <VWysiwyg
          v-model="v.details.$model"
          :label="$t('app.details')"
          class="col-span-2"
          :readonly="readonly"
          :errors="v.details.$errors"
        />

        <VSelect
          v-model="v.status.$model"
          :label="$t('app.status')"
          :options="options.status"
          :readonly="readonly"
          :errors="v.status.$errors"
        />

        <VSelect
          v-model="v.user_type.$model"
          :label="$t('app.user_type')"
          :options="options.user_type"
          :readonly="readonly"
          :errors="v.user_type.$errors"
        />
      </div>
    </template>
  </FormCard>
</template>

<script>
// Mixins
import FormMixin from "@/mixins/FormMixin.js";
// Composables
import useForm from "@/composables/useForm";
import useUser from "@/composables/useUser";

export default {
  mixins: [FormMixin],
  setup(props, context) {
    const { value, rules, options } = useUser();
    const { v, onSave, onCancel } = useForm(props, context, { value, rules });

    return {
      // useUser
      value,
      options,
      // useForm
      v,
      onSave,
      onCancel
    };
  }
};
</script>
