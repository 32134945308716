<template>
  <FormCard>
    <template #title>
      {{ $t("app.edit_profile") }}
    </template>

    <template #content>
      <div class="grid grid-cols-2 gap-x-8 gap-y-2">
        <VText
          v-model="v.email.$model"
          :label="$t('app.email')"
          :errors="v.email.$errors"
          @keyup.enter="onClickSave"
        />

        <VSwitch
          v-model="v.email_notification.$model"
          :label="$t('app.email_notification')"
          true-value="Yes"
          false-value="No"
          :errors="v.email_notification.$errors"
        />

        <VText
          v-model="v.phone.$model"
          :label="$t('app.phone')"
          :errors="v.phone.$errors"
          @keyup.enter="onClickSave"
        />

        <VSwitch
          v-model="v.sms_notification.$model"
          :label="$t('app.sms_notification')"
          true-value="Yes"
          false-value="No"
          :errors="v.sms_notification.$errors"
        />

        <VText
          v-model="v.mobile.$model"
          :label="$t('app.mobile')"
          :errors="v.mobile.$errors"
          @keyup.enter="onClickSave"
        />

        <VSelect
          v-model="v.language.$model"
          :label="$t('app.languages', 1)"
          :options="options.language"
          :readonly="readonly"
          :errors="v.language.$errors"
        />
      </div>
    </template>

    <template #actions>
      <div class="flex justify-between">
        <VButton
          :label="$t('app.export_all_data')"
          class="btn-outline-secondary"
          :is-loading="isExporting"
          @click="onClickExport"
        />

        <div>
          <VButton
            :disabled="readonly"
            class="btn-outline-secondary mr-1"
            :label="$t('app.cancel')"
            @click="onCancel"
          />

          <VButton
            :disabled="readonly"
            class="btn-primary"
            :label="$t('app.save')"
            :is-loading="isLoading"
            @click="onClickSave"
          />
        </div>
      </div>
    </template>
  </FormCard>
</template>

<script>
import { ref } from "vue";
import { useStore } from "@/store";
import { required, email } from "@/i18n/i18n-validators";
// Mixins
import FormMixin from "@/mixins/FormMixin.js";
// Composables
import useForm from "@/composables/useForm";
import useUser from "@/composables/useUser";
import useDownload from "@/composables/useDownload";
import useRequest from "@/composables/useRequest";
// Components
import VButton from "@/components/VButton";

export default {
  components: {
    VButton
  },
  mixins: [FormMixin],
  setup(props, context) {
    // MISC
    const store = useStore();

    // DATA
    const isExporting = ref(false);
    const value = ref({
      email: "",
      phone: "",
      mobile: "",
      email_notification: "No",
      sms_notification: "No",
      language: ""
    });
    const rules = ref({
      email: { required, email },
      phone: {},
      mobile: {},
      email_notification: {},
      sms_notification: {},
      language: { required }
    });

    // Composables
    const { downloadFile } = useDownload();
    const { request } = useRequest();
    const { v, onCancel, onSave } = useForm(props, context, { value, rules });
    const { options } = useUser();

    // Methods
    const onClickSave = async () => {
      const data = await onSave();
      store.dispatch("user/updateUser", data);
    };

    const exportAllData = async () => {
      isExporting.value = true;
      const response = await request({ endpoint: "my.export-all-data" });
      isExporting.value = false;

      return response?.payload?.data?.["download-file"]?.download_path;
    };

    const onClickExport = async () => {
      const url = await exportAllData();
      downloadFile(url);
    };

    return {
      isExporting,
      onClickSave,
      onClickExport,
      // useUser
      options,
      // useForm
      v,
      onCancel
    };
  }
};
</script>
