<template>
  <VCard>
    <template #title>
      {{ $t("app.password") }}
    </template>

    <template v-if="passwordLastChanged" #title-actions>
      <div class="float-right">
        {{ $t("app.last_changed") }}: {{ passwordLastChanged }}
      </div>
    </template>

    <template #content>
      <div v-if="hasConfirmPassword">
        <VPassword
          v-model="value.password"
          :label="$t('app.new_password')"
          input-class="form-control w-full mr-2"
          @keyup.enter="onClickSave"
        />

        <label class="form-label mt-3">
          {{ $t("app.confirm_password") }}
        </label>

        <div class="flex">
          <div class="w-full">
            <VText
              v-model="v.confirm_password.$model"
              type="password"
              class="mr-2"
              :errors="v.confirm_password.$errors"
              @keyup.enter="onClickSave"
            />
          </div>

          <VButton
            class="btn btn-primary self-start"
            :label="$t('app.save')"
            :is-loading="isSaving"
            @click="onClickSave"
          />
        </div>
      </div>

      <div v-else class="flex">
        <div class="w-full mr-2">
          <VPassword
            v-model="value.password"
            :label="$t('app.new_password')"
            input-class="form-control"
            @keyup.enter="onClickSave"
          />
        </div>

        <VButton
          class="btn btn-primary self-end password-btn"
          :label="$t('app.save')"
          :is-loading="isSaving"
          @click="onClickSave"
        />
      </div>
    </template>
  </VCard>
</template>

<script>
import { ref, computed, inject } from "vue";
import { sameAs } from "@/i18n/i18n-validators";
import { useStore } from "@/store";
// Mixins
import FormMixin from "@/mixins/FormMixin.js";
// Composables
import useForm from "@/composables/useForm";
// Components
import VCard from "@/components/VCard";
import VButton from "@/components/VButton";
import VText from "@/components/inputs/VText";
import VPassword from "@/components/inputs/VPassword";

export default {
  components: {
    VCard,
    VButton,
    VPassword,
    VText
  },
  mixins: [FormMixin],
  props: {
    user: {
      type: Object,
      required: true
    },
    isSaving: {
      type: Boolean,
      default: false
    },
    hasConfirmPassword: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    // MISC
    const store = useStore();
    const moment = inject("moment");

    // COMPUTED
    const password = computed(() => value.value.password);
    const dateFormat = computed(() => store.getters["app/date_format"]);
    const passwordLastChanged = computed(() => {
      const value = props.user?.password_last_changed;
      return value ? moment(value).format(dateFormat.value) : "";
    });

    // DATA
    const value = ref({
      password: "",
      confirm_password: ""
    });
    const rules = ref({
      confirm_password: props.hasConfirmPassword
        ? { sameAs: sameAs(password) }
        : {},
      password: {}
    });

    // Composables
    const { v, onSave } = useForm(props, context, { value, rules });

    return {
      value,
      passwordLastChanged,
      // useForm
      v,
      onClickSave: onSave
    };
  }
};
</script>

<style scoped>
.password-btn {
  /* The height of the password meter is 5px with margin-top */
  margin-bottom: 13px;
}
</style>
